<template>
    <svg
        :viewBox.camel="viewBox"
        :aria-labelledby="iconName"
        role="presentation"
        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <title :id="iconName" lang="en">{{iconName}} icon</title>
            <g :fill="iconColor">
                <slot />
            </g>
    </svg>
</template>

<script>
        // :width="width + 'px'"
        // :height="height + 'px'"
export default {
    props: {
        iconName: {
            type: String,
            default: 'box'
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    },
    computed: {
        viewBox() {
            return `0 0 ${this.width} ${this.height}`
        }
    }
}
</script>

<style scoped>
    svg {
        margin: 0 auto;
        display: block;
    }
</style>
